
import Vue from 'vue';
import { mapGetters } from 'vuex';

import theme from 'Theme';
import MarketDataApi from 'Apis/MarketData';
import TradingDataRequest from 'Entities/publicPresenter/TradingDataRequest';
import PublicDataApi from 'Apis/PublicData';
import SpotAssetPairsRequest from 'Entities/publicPresenter/SpotAssetPairsRequest';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import SpotAssetPair from 'Entities/publicPresenter/SpotAssetPair';
import { ADD_SPOT_ASSET_PAIR, setActiveTerminalAssetPairId, updateSpotAssetPairsList } from 'Store/v2/AssetPairs';
import { ADD_ASSET_PAIR } from 'Store/v2/TradingData';
import AssetValue from 'UI/AssetValue.vue';
import Shift from 'UI/Shift.vue';
import Search from 'Control/Search.vue';
import TradingData from 'Entities/publicPresenter/TradingData';
import Preloader from 'Common/Preloader.vue';
import Icon from 'UI/Icon.vue';

interface Props {
    balance: any;
    hideModal: any;
}

interface Data {
    theme: any;
    assetPairs: any[] | null;
    searchValue: string;
    isSearchActive: boolean;
    isSearchToggled: boolean;
    isLoading: boolean;
}

interface Methods {
    noExponentialNumber: (data: number) => string;
    setActiveTerminalAssetPair: (data: SpotAssetPair) => void;
    pairMaker: (tradingData: TradingData, assetPair: any) => any;
    onSearchToggle: (data: boolean) => void;
    onSearchInput: (data: string) => void;
    loadData: () => void;
}

interface Computed {
    quotationAssetCharacter: string;
    activeTerminalAssetPairSymbol: string;
    symbol: string;
    assetPrecision: number;
    total: number;
    totalEquivalent: number;
    free: number;
    freeEquivalent: number;
    summaryHold: number;
    holdEquivalent: number;
    placementId: number;
    placementName: string;
    filteredAssetPairs: any[];
    assetPairsArray: any[];
    balanceUniqueIdentifier: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
    components: {
        Shift,
        AssetValue,
        Search,
        Preloader,
        Icon,
    },
    props: {
        balance: {
            type: Object,
            required: true,
        },
        hideModal: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            theme,
            assetPairs: null,
            searchValue: '',
            isSearchActive: false,
            isSearchToggled: false,
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            activeTerminalAssetPairSymbol: 'AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_SYMBOL',
            activeTerminalPlacementId: 'Placements/activeTerminalPlacementId',
            activeAccountPortfolioPlacementId: 'Balances/GET_ACCOUNT_PORTFOLIO_ACTIVE_PLACEMENT_ID',
        }),
        symbol() {
            return this.balance.assetSymbol;
        },
        assetPrecision() {
            return this.balance.assetPrecision;
        },
        total() {
            return this.balance.total ? (+this.balance.total).round(this.assetPrecision) : 0;
        },
        totalEquivalent() {
            try {
                return this.$store.getters['Balances/GET_BALANCE_TOTAL_EQUIVALENT'](this.balance).round(this.assetPrecision) ?? 0;
            } catch {
                return 0;
            }
        },
        free() {
            if (this.balance.free < 0) {
                return 0;
            }
            return this.balance.free ? (+this.balance.free).round(this.assetPrecision) : 0;
        },
        freeEquivalent() {
            try {
                return this.$store.getters['Balances/GET_BALANCE_FREE_EQUIVALENT'](this.balance).round(this.assetPrecision) ?? 0;
            } catch {
                return 0;
            }
        },
        summaryHold() {
            return ((Number(this.balance.hold ?? 0)) + (Number(this.balance.positionsHold ?? 0))).round(this.assetPrecision);
        },
        holdEquivalent() {
            try {
                return this.$store.getters['Balances/GET_BALANCE_SUMMARY_HOLD_EQUIVALENT'](this.balance).round(this.assetPrecision) ?? 0;
            } catch {
                return 0;
            }
        },
        placementId() {
            if (!this.balance.placementId) {
                return Number(this.$store.state.Placements.placements.find(({ name }) => name.toLowerCase() === this.balance.placementName.toLowerCase())?.id);
            }
            return Number(this.balance.placementId);
        },
        placementName() {
            return this.balance.placementName;
        },
        filteredAssetPairs() {
            return this.assetPairs?.filter(({ symbol }) => symbol && symbol.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1) ?? [];
        },
        assetPairsArray() {
            if (this.isSearchActive) {
                return this.filteredAssetPairs;
            }
            return this.assetPairs?.filter(({ symbol }) => symbol) ?? [];
        },
        balanceUniqueIdentifier() {
            return `${this.balance.assetSymbol}:${this.balance.blockchainName}:${this.balance.placementName}`;
        },
    },
    methods: {
        noExponentialNumber(num) {
            let result = '0.';
            const initialString = String(num);
            const firstPart = initialString.split('e')[0];
            const secondPart = initialString.split('e')[1];
            if (!secondPart) {
                return String(num);
            }
            const integerPart = firstPart.split('.')[0];
            const floatPart = firstPart.split('.')[1];
            if (floatPart) {
                const zeros = (-1 * Number(secondPart)) - integerPart.length;
                for (let i = 1; i <= zeros; i += 1) {
                    result += '0';
                }
                result += integerPart + floatPart;
            } else {
                const zeros = (-1 * Number(secondPart));
                for (let i = 1; i <= zeros; i += 1) {
                    result += '0';
                }
                result += integerPart;
            }
            return result;
        },
        async setActiveTerminalAssetPair(pair) {
            if (!this.$store.state.Placements.maintenanceStatuses.get(this.balance.placementName)) {
                return;
            }

            if (this.$router.currentRoute.path !== '/terminal') {
                await this.$router.push('/terminal');
            }
            if (this.placementId !== this.$store.getters['Placements/activeTerminalPlacementId']) {
                await this.$store.dispatch('Placements/setActiveTerminalPlacementId', this.placementId);
                await this.$store.dispatch(updateSpotAssetPairsList({ placementId: this.placementId, isFirstDownload: false }));
            }
            this.$store.commit(ADD_SPOT_ASSET_PAIR(pair));
            this.$store.commit(ADD_ASSET_PAIR(pair));
            await this.$store.dispatch(setActiveTerminalAssetPairId(pair.id));

            (this as any).hideModal();
        },
        pairMaker(tradingDataPair, spotAssetPairsPair) {
            if (!tradingDataPair || !spotAssetPairsPair) {
                return {};
            }
            const asset = this.$store.state.Assets.assets.find((a) => a.symbol === tradingDataPair.symbol?.split('/')[0]);
            const rate = asset && this.$store.state.Assets.quotations.has(asset.symbol)
                ? this.$store.state.Assets.quotations.get(asset.symbol)![this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL']]
                : null;
            spotAssetPairsPair.ticker = {};
            spotAssetPairsPair.ticker.price = tradingDataPair.currentPrice;
            spotAssetPairsPair.ticker.percentChange = tradingDataPair.priceChangePercent24h;
            spotAssetPairsPair.ticker.volume24h = tradingDataPair.volume24h;
            spotAssetPairsPair.ticker.volume24h_usd = rate ? (tradingDataPair.volume24h ?? 0) * rate : null;
            spotAssetPairsPair.ticker.high24h = tradingDataPair.high24h;
            spotAssetPairsPair.ticker.low24h = tradingDataPair.low24h;
            return spotAssetPairsPair;
        },
        onSearchToggle(value) {
            this.isSearchToggled = value;
        },
        onSearchInput(value) {
            this.searchValue = value;
        },
        async loadData() {
            try {
                this.isLoading = true;
                const { data: assetPairs } = await MarketDataApi.publicGetTradingData(new TradingDataRequest({
                    placementName: this.placementName,
                    assetPairSymbol: this.symbol,
                }));

                let totalSpotAssetPairs: SpotAssetPair[] = [];
                const {
                    data: spotAssetPairs,
                    headers,
                } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
                    placementName: this.placementName,
                    assetPairSymbols: assetPairs.map((p) => (p.symbol ? p.symbol : '')),
                    page: 1,
                    perPage: 300,
                    includeTotal: true,
                }), true);
                totalSpotAssetPairs = [...spotAssetPairs];

                let totalPage;
                if (headers) {
                    totalPage = parsePaginationHeaders(headers).totalPage;
                }
                for (let i = 2; i <= totalPage; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraSpotAssetPairs } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
                        placementName: this.placementName,
                        assetPairSymbols: assetPairs.map((p) => (p.symbol ? p.symbol : '')),
                        page: i,
                        perPage: 300,
                    }));
                    totalSpotAssetPairs = [...totalSpotAssetPairs, ...extraSpotAssetPairs];
                }

                this.assetPairs = assetPairs.map((p) => this.pairMaker(p, totalSpotAssetPairs.find((s) => p.symbol === s.symbol)));
            } finally {
                this.isLoading = false;
            }
        },
    },
    async created() {
        await this.loadData();
    },
    watch: {
        searchValue(value) {
            this.isSearchActive = value.length > 0;
        },
        async balanceUniqueIdentifier() {
            await this.loadData();
        },
    },
});
